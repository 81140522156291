import { GET_REELS } from "../types";

const initalstate = {
    reels: []
}

export default function (state = initalstate, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_REELS:
            return {
                ...state, reels: payload
            }
        default:
            return state;
    }
}