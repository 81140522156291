import { GET_VAHAN_CHECK_CAR_LIST } from "../types";

const initialState = {
  vahanCheckRequestList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_VAHAN_CHECK_CAR_LIST:
      return {
        ...state,
        vahanCheckRequestList: payload?.data,
        total: payload?.total,
      };
    default:
      return state;
  }
}
