type ModuleName =
  | "Dealers List"
  | "User"
  | "Roles"
  | "SH List"
  | "RTO Cases"
  | "Pickup Requests"
  | "Challan"
  | "Photoshoot Services"
  | "D2D Auction"
  | "D2D Requirement";
type PermissionName = "View" | "Edit" | "Add";

export const isAllowed = (
  moduleName: ModuleName,
  permissionName: PermissionName
) => {
  const role = JSON.parse(localStorage.getItem("permissions") || "");
  return role.some((it) => {
    if (it.module === moduleName) {
      return it.permission?.includes(permissionName);
    } else return false;
  });
};

export const getUserPermissions = (roles) => {
  if (!roles) return;
  const array: Array<any> = [];
  for (const moduleKey in roles) {
    for (const submoduleKey in roles[moduleKey].submodules) {
      const submodule = roles[moduleKey].submodules[submoduleKey];
      const permissionArray: Array<any> = [];
      for (const permissionKey in submodule.permissions) {
        const permission = submodule.permissions[permissionKey];
        permissionArray.push(permission.action);
      }
      array.push({ module: submodule.name, permission: permissionArray });
    }
  }
  localStorage.setItem("permissions", JSON.stringify(array));
};
