import { useState } from "react";
import style from "./style.module.scss";
import LargeLogo from "../../res/logo.png";
import MobileLogo from "../../res/mobile_Logo.png";
import { getAbsolute, MAIN_ROUTES } from "../../constants/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { R } from "../../constants/resources";

export const Sidebar = ({
  collapsible = true,
  modal = false,
  onDismissRequest,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigations = [
    {
      icon: R.ic_dashboard,
      text: "Dashboard",
      route: MAIN_ROUTES.DASHBOARD,
    },
    {
      icon: R.ic_rto_services,
      text: "Services",
      route: MAIN_ROUTES.RTO_SERVICES.ROUTE,
      submenu: [
        {
          icon: R.ic_rto_pickup,
          text: "Door Service",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.PICKUP_REQUEST),
        },
        {
          icon: R.ic_rto_cases,
          text: "RTO",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.RTO_CASES),
        },
        {
          icon: R.ic_rto_challan,
          text: "Challan",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.CHALLAN),
        },
        {
          icon: R.ic_vahan_check,
          text: "Vahan Check",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VAHAN_CHECK),
        },
        {
          icon: R.ic_camera,
          text: "Photo Shoot",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.PHOTO_SHOOT),
        },
        {
          icon: R.ic_camera,
          text: "Creative Designing",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.CREATIVE_MAKING),
        },
        {
          icon: R.ic_advertising,
          text: "Social Media",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.SOCIAL_MEDIA),
        },
        {
          icon: R.ic_digital_marketing,
          text: "Digital Marketing",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.DIGITAL_MARKETING),
        },
        {
          icon: R.ic_website_development,
          text: "Website Development",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.WEBSITE_DEVELOPMENT),
        },
        {
          icon: R.ic_service_history,
          text: "Service History",
          route: getAbsolute(MAIN_ROUTES.SERVICE_HISTORY.SERVICE_LIST),
        },
        {
          icon: R.ic_d2d,
          text: "D2D Auction",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.D2D_AUCTION),
        },
        {
          icon: R.ic_d2d,
          text: "D2D Requirement",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.D2D_REQUIREMENT),
        },
        {
          icon: R.ic_d2d,
          text: "Inspection Services", 
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.INSPECTION_REQUEST),
        },
        {
          icon: R.ic_d2d,
          text: "Dealer Reels",
          route: getAbsolute(MAIN_ROUTES.RTO_SERVICES.VIEW_DEALER_USER_REELS),
        },
      ],
    },
    {
      icon: R.ic_dealers,
      text: "Dealers",
      route: MAIN_ROUTES.DEALER_MANAGEMENT.ROUTE,
      submenu: [
        {
          icon: R.ic_dealers,
          text: "Dealer List",
          route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.DEALERS),
        },
        {
          icon: R.ic_role_list,
          text: "Dealer Sub User Role",
          route: getAbsolute(MAIN_ROUTES.DEALER_MANAGEMENT.DEALERSUBUSER),
        },
      ],
    },
    {
      icon: R.ic_master_management,
      text: "Masters",
      route: MAIN_ROUTES.MASTER_MANAGEMENT.ROUTE,
      submenu: [
        {
          icon: R.ic_user_list,
          text: "Users",
          route: getAbsolute(MAIN_ROUTES.MASTER_MANAGEMENT.USERS),
        },
        {
          icon: R.ic_role_list,
          text: "Roles",
          route: getAbsolute(MAIN_ROUTES.MASTER_MANAGEMENT.ROLES),
        },
      ],
    },
    {
      icon: R.ic_rto_services,
      text: "Transaction",
      route: MAIN_ROUTES.TRANSACTIONS.ROUTE,
      submenu: [
        {
          icon: R.ic_rto_challan,
          text: "Service Transaction",
          route: getAbsolute(MAIN_ROUTES.TRANSACTIONS.SERVICE_TRANSACTION),
        },
        {
          icon: R.ic_rto_pickup,
          text: "Bank Transaction",
          route: getAbsolute(MAIN_ROUTES.TRANSACTIONS.BANK_TRANSACTION),
        },
        {
          icon: R.ic_rto_cases,
          text: "Outstanding Transaction",
          route: getAbsolute(MAIN_ROUTES.TRANSACTIONS.OUTSTANDING_TRANSACTION),
        },
      ],
    },
    {
      icon: R.ic_rto_services,
      text: "Inventory",
      route: MAIN_ROUTES.INVENTORY.ROUTE,
      submenu: [
        {
          icon: R.ic_rto_services,
          text: "Inventory Management",
          route: getAbsolute(MAIN_ROUTES.INVENTORY.INVENTORY_LIST),
        },
      ],
    },
    {
      icon: R.ic_financial_services,
      text: "Financial Services",
      route: MAIN_ROUTES.FINANCIALSERVICES.ROUTE,
      submenu: [
        {
          icon: R.ic_loans,
          text: "Customer Loans",
          route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.LOANS_LIST),
        },
        {
          icon: R.ic_loan_insurance,
          text: "Customer Insurance",
          route: getAbsolute(MAIN_ROUTES.FINANCIALSERVICES.INSURANCE_LIST),
        },
      ],
    },
    {
      icon: R.ic_rto_services,
      text: "Lead",
      route: MAIN_ROUTES.LEAD.ROUTE,
      submenu: [
        {
          icon: R.ic_rto_services,
          text: "Upload Buyer Lead",
          route: getAbsolute(MAIN_ROUTES.LEAD.LEAD_LIST),
        },
        {
          icon: R.ic_user_list,
          text: "Customer Management",
          route: getAbsolute(MAIN_ROUTES.LEAD.CUSTOMER_LEAD),
        },
      ],
    },
    {
      icon: R.ic_privacy_policy,
      text: "Privacy Policy",
      route: MAIN_ROUTES.PRIVACY_POLICY,
    },
    {
      icon: R.ic_terms_conditions,
      text: "Terms & Conditions",
      route: MAIN_ROUTES.TERMS_CONDITIONS,
    },
    {
      icon: R.ic_privacy_policy,
      text: "Loan Lender",
      route: MAIN_ROUTES.LOAN_LENDER,
    },
    {
      icon: R.ic_terms_conditions,
      text: "Insurance Lender",
      route: MAIN_ROUTES.INSURANCE_LENDER,
    },
  ];

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className={style["container"]}>
      <div className={style["logo-wrapper"]}>
        {collapsed ? (
          <img
            className={style["small-logo"]}
            src={MobileLogo}
            loading="eager"
            alt="MobileLogo"
          />
        ) : (
          <img
            className={style["large-logo"]}
            loading="eager"
            src={LargeLogo}
            alt="LargeLogo"
          />
        )}
      </div>

      {modal && (
        <div className={style["modal-action-container"]}>
          <img
            onClick={onDismissRequest}
            src={R.ic_close}
            alt="Collapse/Expand Icon"
          />
        </div>
      )}
      <div className={style["navigation-wrapper"]}>
        {collapsible && (
          <div className={style["collapse-toggle-container"]}>
            <span
              style={{ alignSelf: collapsed ? "center" : "flex-start" }}
              className={style["collapse-toggle"]}
              onClick={() => setCollapsed(!collapsed)}
            >
              <img
                style={{ marginLeft: collapsed ? "" : "35px" }}
                className={`${style.expandIcon} img-fluid`}
                src={collapsed ? R.ic_hamburger : R.ic_close}
                alt="Collapse/Expand Icon"
              />
            </span>
          </div>
        )}
        {navigations?.map((navigation, index) => {
          return (
            <Navigation
              key={index}
              navigation={navigation}
              collapsed={collapsed}
              onNavClick={(route) => {
                if (onDismissRequest) onDismissRequest();
                navigate(route);
              }}
              route={location?.pathname}
            />
          );
        })}
      </div>
    </div>
  );
};

const Navigation = ({ navigation, collapsed, onNavClick, route }) => {
  const active = navigation.route === route;
  return (
    <div className={style["navigation"]}>
      <div
        onClick={() => onNavClick(navigation.route)}
        className={[
          style["main"],
          active ? style["active"] : style["inactive"],
        ].join(" ")}
      >
        <img
          style={{ filter: active ? "brightness(0) invert(1)" : "" }}
          loading="eager"
          alt="sidebar_icon"
          src={navigation.icon}
        />
        {!collapsed && <p>{navigation.text}</p>}
      </div>
      {navigation.submenu && (
        <SubMenu
          submenu={navigation.submenu}
          collapsed={collapsed}
          onNavClick={onNavClick}
          route={route}
        />
      )}
    </div>
  );
};

const SubMenu = ({ submenu, collapsed, onNavClick, route }) => {
  return (
    <div className={style["submenu"]}>
      {submenu?.map((menu, index) => {
        const active = route === menu.route;

        const menuStyle = {
          marginLeft: collapsed ? "0" : menu.icon ? "20px" : "35px",
          justifyContent: collapsed ? "center" : "flex-start",
          backgroundColor: active ? "var(--primary)" : "var(--sidebar-bg)",
          color: active ? "#FFFFFF" : "#000000",
        };
        const iconStyle = {
          width: collapsed ? "18px" : "20px",
          filter: active ? "brightness(0) invert(1)" : "",
        };
        return (
          <div
            onClick={() => onNavClick(menu.route)}
            key={index}
            style={menuStyle}
            className={[style["menu"], active ? {} : style["inactive"]].join(
              " "
            )}
          >
            <img style={iconStyle} src={menu.icon} alt="icon" />
            {!collapsed && <p>{menu.text}</p>}
          </div>
        );
      })}
    </div>
  );
}; 
